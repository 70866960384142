import React from 'react';
import styles from './Page.module.scss';

type Props = {
  title?: string,
  children: React.Node,
  isIndex?: boolean,
};

const Page = ({ title, children, isIndex }: Props) => {
  return (
    <div className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && isIndex ? <h2 className={styles['page__title']}>{title}</h2> : <h1 className={styles['page__title']}>{title}</h1> }
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;