// @flow

const getTitle = (name: string) => {
  let title;

  switch (name) {
    case 'twitter':
      title = 'Twitter';
      break;
    case 'facebook':
      title = 'Facebook';
      break;
    case 'github':
      title = 'Github';
      break;
    case 'vkontakte':
      title = 'Vkontakte';
      break;
    case 'telegram':
      title = 'Telegram';
      break;
    case 'email':
      title = 'Email';
      break;
    case 'rss':
      title = 'Rss';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export default getTitle;
