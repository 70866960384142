// @flow
import React from 'react';
import Helmet from 'react-helmet';
import type { Node as ReactNode } from 'react';
import styles from './Layout.module.scss';
import { Twitter } from '../../types';
import Footer from '../Footer';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  keywords?: string,
  author?: string,
  image?: string,
  twitter: Twitter
};

const Layout = ({
  children,
  title,
  description,
  keywords,
  author,
  image,
  twitter,
  isInnerPage
}: Props) => {
  const twitterCard = twitter.card ? 'summary_large_image' : 'summary';

  return (
    <div>
      <div className={styles.layout}>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={author} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
          <meta property="og:site_name" content={title} />
          <meta property="og:description" content={description} />
          <meta name="twitter:card" content={twitterCard} />
          <meta name="twitter:site" content={twitter.site} />
          <meta name="twitter:creator" content={twitter.creator} />
        </Helmet>
        {children}
      </div>
      <Footer isIndex={!isInnerPage}/>
    </div>
  );
};

export default Layout;
