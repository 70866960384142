// @flow
import React from 'react';
import Contacts from '../Contacts';
import Copyright from '../Copyright';
import styles from './Footer.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Footer = ({ isIndex }: Props) => {
  const { author, copyright } = useSiteMetadata();

  return (
    <div className={`${styles['footer']} ${isIndex ? '' : styles['footer-visible']}`}>
      <div className={styles['footer__inner']}>
        <Contacts contacts={author.contacts} isIndex={isIndex} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Footer;
