// @flow
import React from 'react';
import styles from './Copyright.module.scss';

type Props = {
  copyright: string
};

const Copyright = ({ copyright }: Props) => {
  const year = new Date().getFullYear();

  return (
    <div className={styles['copyright']}>
      {year} {copyright}
    </div>
  );
};

export default Copyright;