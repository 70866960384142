// @flow
import React from 'react';
import { withPrefix } from 'gatsby';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import styles from '../components/Sidebar/Author/Author.module.scss';

const NotFoundTemplate = () => {
  const {
    url: siteUrl,
    title: siteTitle,
    subtitle: siteSubtitle,
    keywords: siteKeywords,
    author: siteAuthor,
    twitter: siteTwitter
  } = useSiteMetadata();

  return (
    <Layout title={`Not found | ${siteTitle}`} description={siteSubtitle} keywords={siteKeywords}
            author={siteAuthor.name} image={`${siteUrl}${siteAuthor.photo}`} twitter={siteTwitter}>
      <Sidebar />
      <Page title="Not found">
        <p>The page you are looking for is not found.</p>
        <img
          src={withPrefix('/404.gif')}
          className={styles['notfound__animation']}
          width="250"
          height="241"
          alt=""
        />
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
